import React, { FC } from 'react';
import './kontakt.css';
import '../media-queries/kontakt-media-queries.css';
import Kontaktdaten from '../components/kontaktdaten/kontaktdaten';
import { WhoIsWhoBox } from '../components/who-is-who-box/who-is-who-box';
import mantajImageSrc from '../images/kontakt/mantajImageSrc.jpg';
import dornImageSrc from '../images/kontakt/dornImageSrc.jpg';
import koehlerImageSrc from '../images/kontakt/koehlerImageSrc.jpg';
import platzhalterImageSrc from '../images/kontakt/platzhalterImageSource.jpg';
import sokulImageSrc from '../images/kontakt/sokulImageSrc.jpg';
import erkesImageSrc from '../images/kontakt/erkesImageSrc.jpg';
import frauDornImageSrc from '../images/kontakt/frauDornImageSrc.jpg';
import judithPremImageSrc from '../images/kontakt/judithPremImageSrc.jpg';
import uhlImageSrc from '../images/kontakt/uhlImageSrc.jpg';
import schuetzImageSrc from '../images/kontakt/schuetzImageSrc.jpg';
import cebiImageSrc from '../images/kontakt/cebiImageSrc.jpg';
import BasicStructure from '../components/basic-structure/basic-structure';
import Spendenkonto from '../components/spendenkonto/spendenkonto';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Sidebar from '../components/sidebar/sidebar';
import ScrollUp from '../components/scroll-up/scroll-up';

const Kontakt: FC = () => {
  return (
    <BasicStructure>
      <TitleMetaTags title="Kontakt" />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <Sidebar>
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <div className="contact-page">
          <Kontaktdaten isKontoverbindungVisible={false} largeText={true} />

          <div className="kontakt__who-is-who-box layout--margin-top-120">
            <div className="who-is-who__title">
              <h2 className="heading-square--full who-is-who__title">
                <a href="#who-is-who">Who is Who</a>
              </h2>
            </div>

            <div className="columns layout--margin-bottom">
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={mantajImageSrc}
                  name="Gabriele Mantaj"
                  year="(seit 1996):"
                  description="Mitglied der Stiftungsleitung
            Organisation und Projektleitung: Musik
            am Nachmittag, Musik für Schüler,
            Musik auf Rädern"
                  telefon="089.54 04 118-10"
                  email="mantaj@kulturstiftungmuenchen.de"
                />
              </div>
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={dornImageSrc}
                  name="Jürgen Dorn"
                  year="(seit 2010):"
                  description="Nachfolger von Erich Fischer
            im Stiftungsvorstand"
                  telefon="089.54 04 118-20"
                  email="dorn@kulturstiftungmuenchen.de"
                />
              </div>
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={koehlerImageSrc}
                  name="Eva Köhler"
                  year="(seit 1996):"
                  description="Ehrenamt Stiftungsvorstand"
                  telefon="089.54 04 118-0"
                />
              </div>

              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={sokulImageSrc}
                  name="Svenja Sokul"
                  year=""
                  description="Künstlerisches Betriebsbüro"
                  telefon="089.54 04 118-15"
                  email="sokul@kulturstiftungmuenchen.de"
                />
              </div>
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={erkesImageSrc}
                  name="Johannes Erkes"
                  year="(seit 1997):"
                  description="Musikdirektor der Stiftung"
                  telefon="089.54 04 118-13"
                  email="erkes@kulturstiftungmuenchen.de"
                />
              </div>
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={frauDornImageSrc}
                  name="Renate Dorn"
                  year="(seit 2014):"
                  description="Assistenz Stiftungsvorstand"
                  telefon="089.54 04 118-21"
                  email="rdorn@kulturstiftungmuenchen.de"
                />
              </div>

              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={judithPremImageSrc}
                  name="Judith Prem"
                  year="(seit 2012):"
                  description="freie Mitarbeiterin"
                  telefon="0171.5004510"
                  email="jw.prem@gmx.de"
                />
              </div>
              <div className="col-3">
                <WhoIsWhoBox
                  imageSrc={uhlImageSrc}
                  name="Silvia Uhl"
                  year="(seit 2005):"
                  description="Organisation
            Musik am Nachmittag, Konzerte in
            Alten- und Pflegeheimen"
                  telefon="089.54 04 118-11"
                  email="uhl@kulturstiftungmuenchen.de"
                />
              </div>
            </div>
          </div>
          <div className="kontakt__who-is-who-box layout--margin-top-42">
            <div className="who-is-who__title">
              <div className="heading-square-with-box--full">
                <div className="heading-square-box"></div>
                <h2 className="who-is-who__title2">
                  <a className="title2" href="#who-is-who">
                    STIFTUNGSPROJEKT &quot;MUSIZIEREN STATT
                    <br />
                    KONSUMIEREN&quot; | MUSIK FÜR SCHÜLER
                  </a>
                </h2>
              </div>

              <p className="who-is-who-description">
                Die Ernsthaftigkeit unseres Anliegens wird durch die Bestellung
                der profunden Musiker und Pädagogen Burak &#199;ebi als
                Fachreferent Klavier sowie Thomas Schütz, Fachreferent Chor,
                unterstrichen.
              </p>
              <div className="columns layout--margin-bottom">
                <div className="col-3">
                  <WhoIsWhoBox
                    imageSrc={cebiImageSrc}
                    name="Burak &#199;ebi"
                    description="Fachreferent Klavier"
                    telefon="0176.22771182"
                    email="buraktsch@gmail.com"
                  />
                </div>
                <div className="col-3">
                  <WhoIsWhoBox
                    imageSrc={schuetzImageSrc}
                    name="Thomas Schütz"
                    description="Fachreferent Chor und Mitglied der Stiftungsleitung"
                    telefon="089.5404118-12"
                    email="schuetz@kulturstiftungmuenchen.de"
                  />
                </div>
              </div>
            </div>
          </div>
          <Spendenkonto />
        </div>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
};

export default Kontakt;
