import React from 'react';
import stiftungLogoEinzeilig from '../../images/stiftungsLogo/stiftungs-logo-einzeilig.png';
import stiftungLogoZweizeilig from '../../images/stiftungsLogo/stiftungs-logo-zweizeilig.png';
import '../kontaktdaten/kontaktdaten.css';

interface Props {
  isKontoverbindungVisible: boolean;
  largeText?: boolean;
}

export default function Kontaktdaten(props: Props): JSX.Element {
  const { isKontoverbindungVisible } = props;

  return (
    <>
      <div className="layout--margin flex-center">
        <img
          className="foundation-footer-logo"
          src={stiftungLogoZweizeilig}
          alt="Stiftungslogo"
        />
        <img
          className="foundation-footer-logo--one-liner"
          src={stiftungLogoEinzeilig}
          alt="Stiftunglogo"
        />
      </div>
      <div className="kontaktdaten__container-content">
        <p
          className={`kontaktdaten__adresse ${
            props.largeText ? 'large-text' : ''
          }`}
        >
          81479 München, Dr.-Carl-von-Linde-Str. 9 <br />
          <span className="kontaktdaten__telefon--mobil">
            Telefon <a href="tel:+4908954041180">+49 (0) 89 54 04 11 8 - 0</a>
            &nbsp;|&nbsp;
            <br />
          </span>
          <span className="kontaktdaten__telefon--desktop">
            Telefon +49 (0) 89 54 04 11 8 - 0 &nbsp;|&nbsp;
          </span>
          Telefax +49 (0) 89 54 04 11 8 - 19
        </p>
        <br></br>
        {isKontoverbindungVisible && (
          <p className="kontaktdaten__kontoverbindung">
            Spendenkonto: 368 244 424 |{' '}
            <span className="kontodaten__kontoverbindung--mobil"></span>
            BLZ 700 800 00 | Commerzbank
            <br /> IBAN: DE07 7008 0000 0368 2444 24 |
            <span className="kontodaten__kontoverbindung--mobil"></span>
            <a>&nbsp;BIC: DRESDEFF700</a>
          </p>
        )}
      </div>
    </>
  );
}
