import React, { FC } from 'react';
import { SquareImages } from '../squares-images/squares-images';

import MuenchenOnline from '../../images/kontakt/muenchenOnlineMusikNachmittagSchueler.jpg';

import './spendenkonto.css';
import '../../media-queries/spendenkonto-media-queries.css';

const Spendenkonto: FC = () => {
  return (
    <div className="spendenkonto__container" id="spendenaufruf">
      <hr />
      <h2 className="heading-square--full spendenkonto__title">
        IHRE IDEEN? IHR BEITRAG?
      </h2>
      <p className="spendenkonto__beitragsaufruf">
        Engagieren Sie sich kreativ oder vererben, schenken, spenden Sie
        steuerbegünstigt für einen Solitär in der Stiftungslandschaft, der nicht
        durch Worte sondern Taten weit in Zeit und Raum hineinwirkt!
      </p>
      <hr />

      <img
        className="muenchen_online_image"
        src={MuenchenOnline}
        alt="Musik am Nachmittag Muenchen online"
      />
      <hr />

      <h2 className="heading-square--full spendenkonto__title">SPENDENKONTO</h2>
      <div id="spendenkonto" className="spendenkonto__kontodaten">
        <p>Empfänger: Internationale Kulturstiftung</p>
        <p>Bank: Commerzbank AG</p>
        <p>IBAN: DE07 7008 0000 0368 2444 24</p>
        <p>BIC: DRESDEFF700</p>
        <p className="spendenkonto__spendenquittung">
          Spendenquittung ab 200,- EURO
        </p>
      </div>
    </div>
  );
};

export default Spendenkonto;
