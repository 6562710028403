import React from 'react';
import './who-is-who-box.css';

interface Props {
  name: string;
  year?: string;
  description: string;
  telefon?: string;
  imageSrc: string;
  email?: string;
}

export function WhoIsWhoBox(props: Props): JSX.Element {
  const { name, year, description, telefon, imageSrc, email } = props;

  return (
    <div className="who-is-who-box" id="who-is-who">
      <div className="who-is-who-box__image-container">
        <img className="who-is-who-box__image" src={imageSrc} />
      </div>

      <div className="who-is-who-box__content">
        <div className="who-is-who-box__name-jahr">
          <div className="who-is-who-box__name">{name}</div>
          <div className="who-is-who-box__jahr-size">{year}</div>
        </div>
        <div className="who-is-who-box__description">
          <p>{description} </p>
        </div>

        <div className="who-is-who-box__contact-info">
          <p className="who-is-who-box__telefon">
            Telefon:&nbsp;
            <a className="who-is-who-box__telefonnummer" href="tel: ">
              {telefon}
            </a>
            <br />
            <a className="who-is-who-box__email" href="mailto: ">
              {email}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
